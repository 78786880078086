var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-justify-center main-body"},[_c('back-button',{attrs:{"color":_vm.companyData.companyColor},on:{"back":function($event){return _vm.$router.push({name:'LeasingDashboard'})}}}),(_vm.loading)?_c('loader',{attrs:{"color":_vm.getColor,"loading":_vm.loading}}):_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('p',{staticClass:"header-txt",style:({color: _vm.companyData.companyColor})},[_vm._v("All Hiring Criteria")]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.createCriteria}},[_c('icons',{attrs:{"name":"plus-icon","color":_vm.companyData.companyColor}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"theme":"none","mobile-breakpoint":0,"item-key":"id","single-select":false,"height":"available","items":_vm.allHiringCriteria},scopedSlots:_vm._u([{key:"header.vehicleType",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Vehicle Type")])]},proxy:true},{key:"item.vehicleType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status"},[_c('span',{staticClass:"mt-1 mr-2"},[_c('VehicleTypeStatus',{attrs:{"statusText":item.vehicle.vehicleType}})],1),_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.vehicle.vehicleBrand)+" "+_vm._s(item.vehicle.vehicleType)+" - "+_vm._s(item.vehicle.vehicleCapacity))])])]}},{key:"header.routes",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Routes")])]},proxy:true},{key:"item.routes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.departureCity)+" "+_vm._s(item.departureState)+" - "+_vm._s(item.destinationCity)+" "+_vm._s(item.destinationState))])]}},{key:"header.discountPercentage",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Discount Percentage (%)")])]},proxy:true},{key:"item.discountPercentage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.discountValue)+"%")])]}},{key:"header.hirePrice",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Price")])]},proxy:true},{key:"item.hirePrice",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v("₦"+_vm._s(_vm.formatPrice(item.hirePrice)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-end"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.companyData.companyColor}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.routeToEdit(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer lighter-text",staticStyle:{"color":"black"}},[_c('icons',{staticClass:"mr-2",attrs:{"color":_vm.companyData.companyColor,"name":"edit-hiring"}}),_vm._v(" Edit ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDeleteModal(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer lighter-text",staticStyle:{"color":"black"}},[_c('icons',{staticClass:"mr-2",attrs:{"color":_vm.companyData.companyColor,"name":"delete-hiring"}}),_vm._v(" Delete ")],1)],1)],1)],1)]}}],null,true)})],1),_c('delete-modal',{attrs:{"dialog":_vm.dialog,"done-loading":_vm.loading,"description":"Are you sure you want to delete this criteria?"},on:{"close":_vm.closeModal,"deleteAction":_vm.deleteHiringCriteria}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import Icons from "@/components/reuseables/Icons.vue";
import {mapGetters} from "vuex";

export default {
  name: 'BackButton',
  components: {Icons},
  props:{
    title:{
      type: [String],
      default: "Back"
    },
    color:{
      type: [String],
      default: "#004AAD"
    }

  },
  computed:{
    ...mapGetters("companyProfile",["companyData"])
  }
}

</script>

<template>
<div>
  <v-btn class="btn" @click="$emit('back')">
    <icons name="back-arrow" :color="color" class="tw-mr-3"/>
    <span style="text-transform: capitalize; " :style="{color: color}" class="btn">{{title}}</span>
  </v-btn>
</div>
</template>

<style scoped lang="scss">
.btn{
  font-family: 'Inter',sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

</style>
<script>
import {deleteHiringCriteria, getAllHiringCriteria} from "@/services/api/APIService";
import BackButton from "@/components/reuseables/BackButton.vue";
import Icons from "@/components/reuseables/Icons.vue";
import VehicleTypeStatus from "@/components/reuseables/VehicleTypeStatus.vue";
import {mapGetters} from "vuex";
import Loader from "@/components/reuseables/Loader.vue";
import DeleteModal from "../../reuseables/DeleteModal.vue";

export default {
  name: 'AllHiringCriteria',
  components: {DeleteModal, Loader, VehicleTypeStatus, Icons, BackButton},

  data(){
    return{
      dialog: false,
      loading: false,
      headers:[
        {
          text: 'Vehicle Type',
          value: 'vehicleType'
        },
        {
          text: 'Routes',
          value: 'routes'
        },

        {
          text: 'Discount Percentage(%)',
          value: 'discountPercentage'
        },
        {
          text: 'Price',
          value: 'hirePrice'
        },
        {
          value: 'actions'
        },
      ],
      allHiringCriteria:[],
      hiringCriteriaId: "",
      selectedCriteria:{}

    }
  },


  computed:{
    ...mapGetters("companyProfile",["companyData"]),


    getColor() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      let color = "#0044AD";
      if (data) {
        color = data.companyColor;
      }
      return color;
    },
  },


  methods:{
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    routeToEdit(item){
      this.$store.dispatch('leasingComponent/setSelectedCriteria', item);
      sessionStorage.setItem("hiringCriteriaId", item.id);
      this.$router.push({name: "EditHiringCriteria"})
    },

    closeModal(){
      this.dialog = !this.dialog
    },

    openDeleteModal(item) {
      this.selectedCriteria = item;
      console.log(this.selectedCriteria,"the Criteria to delete")
      this.dialog = true;
    },

    async deleteHiringCriteria(){
      this.loading = true
      let data = {}

      data.id = this.selectedCriteria.id
      data.transportCompanyId = this.companyData.id
      console.log(data, "api data")
      try {
        const response = await deleteHiringCriteria(data)
        console.log(response)
        this.$displaySnackbar({
          message: response.data.details,
          success: true
        });
        this.loading = false
        await this.getAllHiringCriteria();
        this.closeModal()
      }catch (error){
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false
        });
        this.loading = false
        this.closeModal()
        console.log(error.response)
      }
    },

    createCriteria(){
      this.$router.push({name: 'CreateHiringCriteria'})
    },
    async getAllHiringCriteria(){
      this.loading = true
      let data ={}
      data.transportCompanyId = this.companyData.id
      await getAllHiringCriteria(data).then(res => {
        this.allHiringCriteria = res.data.map((item, index) => ({
          ...item,
          serialNo: index + 1
        }));
      }).catch(err => {
        this.loading = false;
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        console.log(err)
      })
      console.log(this.allHiringCriteria)
    },
  },

  async created(){
    this.loading = true;
    await this.getAllHiringCriteria()
    this.loading = false;
  }
}

</script>

<template>
<div class="tw-w-full tw-justify-center main-body">

  <back-button @back="$router.push({name:'LeasingDashboard'})" :color="companyData.companyColor"/>
  <loader :color="getColor" v-if="loading" :loading="loading" />
  <div class="tw-flex tw-justify-center" v-else>
    <div class="table">
      <div class="tw-flex tw-justify-between">
        <p class="header-txt" :style="{color: companyData.companyColor}">All Hiring Criteria</p>
        <div style="cursor: pointer" @click="createCriteria"><icons name="plus-icon" :color="companyData.companyColor"/></div>
      </div>
      <v-data-table :headers="headers" theme="none" :mobile-breakpoint="0" item-key="id" :single-select="false"
                    height="available"
                    :items="allHiringCriteria"
      >

        <template #header.vehicleType>
          <span class="table-header">Vehicle Type</span>
        </template>

        <template v-slot:[`item.vehicleType`]="{ item }">
          <div class="status">
          <span class="mt-1 mr-2"><VehicleTypeStatus :statusText="item.vehicle.vehicleType"></VehicleTypeStatus></span>
          <span class="table-header-values">{{ item.vehicle.vehicleBrand}} {{ item.vehicle.vehicleType}} - {{ item.vehicle.vehicleCapacity}}</span>
          </div>
        </template>

        <template #header.routes>
          <span class="table-header">Routes</span>
        </template>
        <template v-slot:[`item.routes`] ="{item}">
          <span class="table-header-values">{{item.departureCity}} {{item.departureState}} - {{item.destinationCity}} {{item.destinationState}}</span>
        </template>
        <template #header.discountPercentage>
          <span class="table-header">Discount Percentage (%)</span>
        </template>

        <template v-slot:[`item.discountPercentage`]="{ item }">
          <span class="table-header-values">{{item.discountValue}}%</span>
        </template>

        <template #header.hirePrice>
          <span class="table-header">Price</span>
        </template>
        <template v-slot:[`item.hirePrice`]="{ item }">
          <span class="table-header-values">₦{{ formatPrice(item.hirePrice)}}</span>
        </template>


        <template v-slot:[`item.actions`]="{item}">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-end">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>

              <v-list-item class="" @click="routeToEdit(item)">
                <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                  <icons :color="companyData.companyColor" name="edit-hiring" class="mr-2"/>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="" @click="openDeleteModal(item)">
                <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                  <icons :color="companyData.companyColor" name="delete-hiring" class="mr-2" />
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

      </v-data-table>
    </div>
    <delete-modal
        :dialog="dialog"
        @close="closeModal"
        @deleteAction="deleteHiringCriteria"
        :done-loading="loading"
        description="Are you sure you want to delete this criteria?"
    />
  </div>


</div>
</template>

<style scoped lang="scss">

.header-container{
  height: fit-content;
  width :187px;
  padding: 13px;
  border-radius: 10px;
  background: rgba(253, 255, 252, 0.4);
}
.all-hire-txt{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #004AAD;
}
.table{
  width: 100%;
  border-radius: 10px;
  margin-top: 50px;
  background: #FFFFFF;
  padding: 50px;
  @media screen and (max-width: 768px){
    margin-left: 100px;
  }
  @media screen and (max-width: 375px){
    width: 100%;
    margin-right: 100px;
  }
}
.header-txt{
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004AAD;
}
.table-header{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
.table-header-values{
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
.status{
  display: flex;
}
.lighter-text{
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
</style>
<script>

export default {
  name: 'VehicleTypeStatus',
  props: {
    statusText: {
      type: String,
      required: true
    }
  },

  methods:{
    getStatusColour(text){
      if(text === "LUXURIOUS BUS"){
        return '#4582D3'
      }else if (text === "MINIVAN"){
        return "#D0228E"
      }else if (text === "COASTER BUS") {
        return "#D3766A"
      }else if (text === "VAN"){
        return "#4582D3"
      }else if (text === "LEXUS R350"){
        return "#4D8987"
      }else if (text === "BUS"){
        return "#BA68C8"
      }else if (text === "HONDA ACCORD"){
        return "#263238"
      }else if (text === "SCHOOL BUS"){
        return "#959595"
      } else if (text === "HIGH LANDER"){
        return "#F1B8B0"
      }else if (text === "TOYOTA MATRIX"){
        return "#3CE43C"
      }else if (text === "BMW"){
        return "#1E5770"
      }else if (text === "MITSBU"){
        return "#1E5770"
      }else return "#4D8987"
    }
  }
}
</script>

<template>
  <div>

    <div  :style="{ width: '9px', height: '9px', borderRadius: '50%', backgroundColor: getStatusColour(statusText) }"></div>

  </div>
</template>

<style scoped lang="scss">

</style>